import {
  AnimatedItem,
  ErrorCard,
  Margin,
  StatusCard,
} from '@kivra/react-components';
import type { Agreement } from '@sdk/agreement';
import React from 'react';
import { AgreementDocumentContainer } from '../../containers/agreement-document-container';
import { getStatus, type AgreementStatus } from '../../core';
import { getCopy } from '../../utils/copy';
import { AgreementStatus as AgreementStatusComponent } from '../message-card/agreement-status';
import { AgreementCard } from './agreement-card';

interface Props {
  agreement: Agreement;
  maxPollAttemptsExceeded: boolean;
}

export const AgreementPage = ({
  agreement,
  maxPollAttemptsExceeded,
}: Props): React.JSX.Element => {
  const status = getStatus(agreement);

  const shouldFetchDocument = !(
    status === 'generating' || status === 'revoked'
  );

  return (
    <>
      <Margin betweenY={8}>
        <StatusBanner status={status} tenantName={agreement.tenant.name} />
        <AnimatedItem>
          <AgreementCard agreement={agreement} />
        </AnimatedItem>
        {shouldFetchDocument && (
          <AgreementDocumentContainer agreement={agreement} />
        )}
        <AgreementStatusComponent
          agreement={agreement}
          maxPollAttemptsExceeded={maxPollAttemptsExceeded}
        />
      </Margin>
    </>
  );
};

const StatusBanner = ({
  status,
  tenantName,
}: {
  status: AgreementStatus;
  tenantName: string;
}): React.JSX.Element | null => {
  switch (status) {
    case 'revoked':
      return (
        <ErrorCard>
          <ErrorCard.Title>
            {getCopy('signatures__status_bar_revoked', {
              sender: tenantName,
            })}
          </ErrorCard.Title>
        </ErrorCard>
      );
    case 'waitingForOthers':
      return (
        <StatusCard variant="information">
          <StatusCard.Title>
            {getCopy('signatures__status_bar_waiting_for_others')}
          </StatusCard.Title>
        </StatusCard>
      );
    case 'completed':
      return (
        <StatusCard variant="success">
          <StatusCard.Title>
            {getCopy('signatures__status_bar_completed')}
          </StatusCard.Title>
        </StatusCard>
      );
    case 'shouldSign':
    case 'generating':
      return null;
  }
};
