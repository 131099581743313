import {
  Body,
  css,
  FullPageLoader,
  Heading,
  useOnMount,
} from '@kivra/react-components';
import type { Agreement } from '@sdk/agreement';
import { getAgreements } from '@sdk/agreement';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { AboutKivraFooter } from '../components/about-kivra-footer';
import { AgreementsContent } from '../components/agreements/agreements-content';
import { ContentWrapper } from '../components/content-wrapper';
import { Main } from '../components/main';
import {
  ErrorIcon,
  MessageCard,
} from '../components/message-card/message-card';
import { SignaturesTopBar } from '../components/top-bar';
import { useUserId } from '../context/global-context';
import { getCopy } from '../utils/copy';

export const AgreementsPageContainer = (): React.JSX.Element => {
  const userId = useUserId();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [agreements, setAgreements] = useState<Agreement[]>();

  useOnMount(async () => {
    try {
      const response = await getAgreements(userId);
      let onlyAgreement: Agreement | undefined = undefined;
      if (response.length === 1) {
        onlyAgreement = response[0];
      }

      if (onlyAgreement?.user.role === 'signer') {
        // If there is only one agreement list view is not needed to be shown
        // Move straight to agreement page. Delegates will be used to handle
        // more than one agreement - so they will always see list.
        history.push(onlyAgreement.key, { agreement: response[0] });
        return;
      }
      setAgreements(response);
    } catch (_) {
      setError(true);
    }
    setLoading(false);
  });

  const successElement = agreements && (
    <AgreementsContent agreements={agreements} />
  );

  const errorElement = error && (
    <MessageCard>
      <ErrorIcon />
      <Heading size="medium" align="center">
        {getCopy('error_generic__title')}
      </Heading>
      <Body size="medium" align="center" gutterBottom>
        {getCopy('signatures__get_agreements_error')}
      </Body>
    </MessageCard>
  );

  const footerElement = agreements && <AboutKivraFooter />;

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <SignaturesTopBar />
      <Main className={css({ display: 'flex', flexDirection: 'column' })}>
        <ContentWrapper>
          {successElement}
          {errorElement}
        </ContentWrapper>
        {footerElement}
      </Main>
    </>
  );
};
