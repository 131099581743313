import type { Agreement } from '@sdk/agreement';
import React from 'react';
import { Heading, Flex, css } from '@kivra/react-components';
import { getCopy } from '../../utils/copy';
import { ListGroups } from './list-groups';
import { NoAgreements } from './no-agreements';

interface Props {
  agreements: Agreement[];
}

export const AgreementsContent = ({ agreements }: Props): React.JSX.Element => {
  return (
    <Flex
      direction="column"
      className={css({ marginTop: '$spacing-32' })}
      dataTestId="AgreementsContent"
    >
      {agreements.length ? (
        <>
          <Heading size="large">{getCopy('signatures__label_welcome')}</Heading>
          <ListGroups agreements={agreements} />
        </>
      ) : (
        <NoAgreements />
      )}
    </Flex>
  );
};
