import {
  AnimatedItem,
  css,
  Flex,
  Heading,
  Margin,
  styled,
} from '@kivra/react-components';
import { ArrowRightIcon } from '@kivra/react-components/icons';
import type { Agreement } from '@sdk/agreement';
import React from 'react';
import { Link } from 'react-router-dom';
import { useConfig } from '../../context/global-context';
import type { AgreementStatus } from '../../core';
import { getStatus } from '../../core';
import { ExpirationDateTexts } from '../expiration-date-text';

interface Props {
  agreement: Agreement;
}

export const ListItem = ({ agreement }: Props): React.JSX.Element => {
  const { kivra_static_origin } = useConfig();
  const disabled = getStatus(agreement) === 'revoked';
  const rightIconElement = !disabled && (
    <Margin left={8}>
      <ArrowRightIcon size={24} />
    </Margin>
  );

  const listContentElement = (
    <AnimatedItem>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <ImageWrapper>
            <img
              className={css({
                height: '3rem',
                width: '3rem',
                borderRadius: '$radius-circle',
              })}
              src={`${kivra_static_origin}/img/tenant/${agreement.tenant.key}/icon.png`}
              alt={`${agreement.tenant.name} logo`}
            />
          </ImageWrapper>
          <div>
            <Heading size="small">{agreement.subject}</Heading>
            <ExpirationDateTexts agreement={agreement} />
          </div>
        </Flex>
        {rightIconElement}
      </Flex>
    </AnimatedItem>
  );

  return disabled ? (
    <div key={agreement.key} className={listItemStyle(getStatus(agreement))}>
      {listContentElement}
    </div>
  ) : (
    <NoStyleLink
      key={agreement.key}
      to={`/${agreement.key}`}
      className={listItemStyle(getStatus(agreement))}
    >
      {listContentElement}
    </NoStyleLink>
  );
};

const listItemStyle = (status: AgreementStatus): string =>
  css({
    color: '$text-secondary',
    position: 'relative',
    padding: '$spacing-12',
    borderBottom: '1px solid $border-subtle',
    '&:last-child': {
      borderBottom: 0,
    },
    backgroundColor:
      status === 'revoked' ? '$surface-neutral' : '$background-secondary',
  });

const NoStyleLink = styled(Link)({
  display: 'block',
  color: '$text-primary',
  textDecoration: 'none',
  transition: '.2s',
  '&:hover': {
    backgroundColor: '$hover-background',
  },
});

const ImageWrapper = styled.div({
  height: '3rem',
  width: '3rem',
  marginRight: '$spacing-12',
});
