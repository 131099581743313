import { Button, Margin } from '@kivra/react-components';
import type { AgreementParty } from '@sdk/agreement';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { sortByRole } from '../../core';
import { getCopy } from '../../utils/copy';
import { Signer } from './signer';

interface Props {
  parties: AgreementParty[];
  showParties: boolean;
}

export const Signers = ({ parties, showParties }: Props): React.JSX.Element => {
  const [showAllParties, setShowAllParties] = useState<boolean>(showParties);

  const handleShowSignerClick = (): void => setShowAllParties(s => !s);

  const sortedParties = parties.sort(sortByRole);
  const mappedParties = sortedParties.map((signer, index) => (
    <Signer
      key={index}
      name={signer.name}
      email={signer.email}
      signDate={signer.signDate}
      role={signer.role}
    />
  ));
  return (
    <AnimatePresence initial={false}>
      {showAllParties && (
        <motion.section
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { height: 'auto' },
            collapsed: { height: 0, overflow: 'hidden' },
          }}
          transition={{ duration: 0.2 }}
        >
          {mappedParties}
        </motion.section>
      )}
      <Margin bottom={8} top={8}>
        <Button variant="secondary" onClick={handleShowSignerClick}>
          {showAllParties
            ? getCopy('signatures__hide_parties')
            : `${getCopy('signatures__show_all_parties')} (${parties.length})`}
        </Button>
      </Margin>
    </AnimatePresence>
  );
};
