import type { Agreement } from '@sdk/agreement';
import React from 'react';
import { styled } from '@kivra/react-components';
import { getStatus } from '../../core';
import { AgreementHeader } from './agreement-header';
import { Signers } from './signers';

interface Props {
  agreement: Agreement;
}

export const AgreementCard = ({ agreement }: Props): React.JSX.Element => {
  return (
    <Card data-test-id="AgreementCard">
      <AgreementHeader agreement={agreement} />
      <Signers
        parties={agreement.parties}
        showParties={getStatus(agreement) === 'shouldSign'}
      />
    </Card>
  );
};

const Card = styled.div({
  backgroundColor: '$background-secondary',
  paddingLeft: '$spacing-16',
  paddingRight: '$spacing-16',
  paddingTop: '$spacing-16',
  paddingBottom: '$spacing-8',
  boxShadow: '$shadow-floating',
  borderRadius: '$radius-medium',
});
