import { styled } from '@kivra/react-components';
import type { ReactNode } from 'react';
import React from 'react';

type Props = {
  children: ReactNode;
};

export const ContentWrapper = ({ children }: Props): React.JSX.Element => {
  return (
    <Wrapper data-test-id="ContentWrapper">
      <InnerWrapper>{children}</InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div({ flex: '1 0 auto', position: 'relative' });

const InnerWrapper = styled.div({
  padding: '$spacing-8',
  margin: 'auto',
  maxWidth: '40rem',
});
