import { format } from 'date-fns';
import React from 'react';
import {
  Body,
  Caption,
  css,
  Flex,
  Heading,
  styled,
} from '@kivra/react-components';
import { getCopy } from '../../utils/copy';

interface Props {
  name: string;
  email: string;
  signDate: string | null;
  role: string;
}

export const Signer = ({
  name,
  email,
  signDate,
  role,
}: Props): React.JSX.Element => {
  const delegateElement = role === 'delegate' && (
    <Caption color="$text-secondary">
      {getCopy('signatures__delegate').toUpperCase()}
    </Caption>
  );

  const signedElement = signDate ? (
    <Body size="small" color="$text-secondary">
      {`${getCopy('signatures__signed_at')} ${format(
        new Date(signDate),
        'yyyy-MM-dd'
      )}`}
    </Body>
  ) : (
    <ItalicBody size="small" color="$text-secondary">
      {getCopy('signatures__waiting_for_signing')}
    </ItalicBody>
  );
  return (
    <>
      <Flex justifyContent="space-between">
        <Flex direction={'column'}>
          {delegateElement}
          <Heading size="xsmall">{name}</Heading>
          <Body
            size="small"
            color="$text-secondary"
            className={css({ wordBreak: 'break-all' })}
          >
            {email}
          </Body>
        </Flex>
        <div className={css({ height: '100%', alignSelf: 'end' })}>
          {signedElement}
        </div>
      </Flex>
      <Border />
    </>
  );
};

const Border = styled.div({
  marginTop: '$spacing-12',
  marginBottom: '$spacing-12',
  borderBottom: '1px solid $border-subtle',
  '&:last-child': {
    borderBottom: '0',
    marginBottom: '$spacing-8',
  },
});

const ItalicBody = styled(Body)({
  fontStyle: 'italic',
  paddingRight: '$spacing-2',
});
