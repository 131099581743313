import {
  css,
  Flex,
  Heading,
  Illustration,
  Margin,
} from '@kivra/react-components';
import React from 'react';
import { getCopy } from '../../utils/copy';

export const NoAgreements = (): React.JSX.Element => {
  return (
    <Margin top={56}>
      <Flex alignItems="center" justifyContent="center" direction="column">
        <Illustration.SignatureEmpty
          className={css({ margin: '$spacing-40' })}
        />
        <Heading size="medium">{getCopy('signatures__no_agreements')}</Heading>
      </Flex>
    </Margin>
  );
};
