import type { ErrorInfo } from 'react';
import React from 'react';
import { ErrorBoundary, KivraTheme } from '@kivra/react-components';
import { userSession } from '@kivra/sdk/identity';
import {
  captureException,
  error as errorBreadcrumb,
  setSentryUser,
} from '@kivra/sdk/log';
import { Routes } from './router/routes';
import type { Config, Copy } from './types';
import { GlobalContextProvider } from './context/global-context';
import { setCopy } from './utils/copy';

interface AppProps {
  copy: Copy;
  config: Config;
}

function onAppError(error: Error, errorInfo: ErrorInfo): void {
  errorBreadcrumb('ErrorBoundary', errorInfo);
  captureException(error);
}

export const App = ({ copy, config }: AppProps): React.JSX.Element | null => {
  const userId = userSession.getItem('userId');

  if (!userId) {
    captureException(new Error('No userId in session storage'));
    return null;
  }
  setSentryUser(userId);
  setCopy(copy);

  const globalContext = {
    config,
    userId,
    global: window,
  };
  return (
    <KivraTheme>
      <ErrorBoundary onCatch={onAppError}>
        <GlobalContextProvider value={globalContext}>
          <Routes />
        </GlobalContextProvider>
      </ErrorBoundary>
    </KivraTheme>
  );
};
