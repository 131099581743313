import { createContext, useContext } from 'react';
import type { AgreementDocument } from '@sdk/agreement';
import type { LegitimateAny } from '@kivra/sdk/types/util/any';

interface AgreementDocumentStateContext {
  value?: AgreementDocument;
  set: (agreementDocument: AgreementDocument | undefined) => void;
}

export interface AgreementContext {
  agreementDocumentState: AgreementDocumentStateContext;
}

export const AgreementContext = createContext<AgreementContext>(
  null as LegitimateAny
);

const {
  Consumer: AgreementContextConsumer,
  Provider: AgreementContextProvider,
} = AgreementContext;

const hookCreator =
  <T>(map: (tc: AgreementContext) => T) =>
  (): T => {
    const agreement = useContext(AgreementContext);
    return map(agreement);
  };

export const useAgreementDocumentState = hookCreator(
  t => t.agreementDocumentState
);

export { AgreementContextProvider, AgreementContextConsumer };
