import { Body, Heading, Margin, useOnMount } from '@kivra/react-components';
import { sleep } from '@kivra/sdk/common';
import type { Agreement, AgreementDocument } from '@sdk/agreement';
import { getAgreement } from '@sdk/agreement';
import React, { useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { AboutKivraFooter } from '../components/about-kivra-footer';
import { AgreementPage } from '../components/agreement/agreement-page';
import { SignButton } from '../components/agreement/sign-button';
import { ContentWrapper } from '../components/content-wrapper';
import { Main } from '../components/main';
import {
  ErrorIcon,
  MessageCard,
} from '../components/message-card/message-card';
import { SignaturesTopBar } from '../components/top-bar';
import type { AgreementContext } from '../context/agreement-context';
import { AgreementContextProvider } from '../context/agreement-context';
import { useConfig, useUserId } from '../context/global-context';
import { getStatus } from '../core';
import { getCopy } from '../utils/copy';

export const AgreementPageContainer = (): React.JSX.Element => {
  const { state: locationState } = useLocation<
    { agreement?: Agreement } | undefined
  >();
  const { agreementKey } = useParams<{ agreementKey: string }>();
  const userHasOneAgreement = Boolean(locationState?.agreement);

  const {
    kivra_signatures_generating_agreement_polling_attempts:
      maxPollingAttemptsString,
    kivra_signatures_generating_agreement_polling_freq_ms: pollingFreqMs,
  } = useConfig();

  const maxPollingAttempts = parseInt(maxPollingAttemptsString);
  const userId = useUserId();
  const [agreementDocument, setAgreementDocument] =
    useState<AgreementDocument>();
  const [error, setError] = useState(false);
  const [agreement, setAgreement] = useState<Agreement>();
  const pollingAttempts = useRef(0);

  const fetchAgreement = async (): Promise<void> => {
    const shouldRetryOnGenerating = (agreement: Agreement): boolean => {
      return (
        getStatus(agreement) === 'generating' &&
        maxPollingAttempts > pollingAttempts.current
      );
    };
    try {
      const response = await getAgreement(userId, agreementKey);
      setAgreement(response);
      if (shouldRetryOnGenerating(response)) {
        setAgreementDocument(undefined);
        await sleep(pollingFreqMs);
        pollingAttempts.current = pollingAttempts.current + 1;
        await fetchAgreement();
      }
    } catch (_) {
      setError(true);
    }
  };

  useOnMount(async () => {
    await fetchAgreement();
  });

  const agreementContext: AgreementContext = {
    agreementDocumentState: {
      value: agreementDocument,
      set: setAgreementDocument,
    },
  };

  const isSignable =
    agreement && agreementDocument && getStatus(agreement) === 'shouldSign';

  return (
    <AgreementContextProvider value={agreementContext}>
      <SignaturesTopBar goBackEnabled={!userHasOneAgreement} />
      <Main>
        <ContentWrapper>
          {agreement && (
            <AgreementPage
              agreement={agreement}
              maxPollAttemptsExceeded={
                pollingAttempts.current >= maxPollingAttempts
              }
            />
          )}
          {error && (
            <MessageCard>
              <ErrorIcon />
              <Heading size="medium" align="center">
                {getCopy('error_generic__title')}
              </Heading>
              <Body size="medium" align="center" gutterBottom>
                {getCopy('signatures__document_not_fetched')}
              </Body>
            </MessageCard>
          )}
        </ContentWrapper>
        {userHasOneAgreement && (
          /**
           * When sign button is shown extra padding added
           * so sign button doesn't block footer
           * */
          <Margin top={64}>
            <AboutKivraFooter
              extraPadding={
                agreementDocument &&
                agreement &&
                getStatus(agreement) === 'shouldSign'
              }
            />
          </Margin>
        )}
        {isSignable && (
          <SignButton agreementKey={agreement.key} onSigned={fetchAgreement} />
        )}
      </Main>
    </AgreementContextProvider>
  );
};
