import { Body, Button, Dialog, styled } from '@kivra/react-components';
import { BankIdIcon } from '@kivra/react-components/icons';
import React from 'react';
import { getCopy } from '../../utils/copy';
/**
 * This is a Copy from this file in kivra_settings. It will be moved to react-components at a later stage.
 * https://github.com/kivra/kivra_settings/blob/7b78add4d835691b4fdc1673d2dd5d28f1ea9fed/src/components/bankId/BankIdSelectDeviceDialog.tsx
 */

export type BankIdSelectDeviceDialogType =
  | 'select-device'
  | 'same-device'
  | 'other-device'
  | null;

interface Props {
  open: boolean;
  onSelectedDevice: (device: BankIdSelectDeviceDialogType) => void;
  anchorRef: React.RefObject<HTMLElement>;
  onAbort: () => unknown;
}

export function BankIdSelectDeviceDialog({
  open,
  onSelectedDevice,
  anchorRef,
  onAbort,
}: Props): React.JSX.Element {
  return (
    <Dialog.Passive
      dataTestId="bankid-select-device-dialog"
      open={open}
      title={getCopy('qrcode__bankid__screen__title')}
      onClose={onAbort}
      ariaLabelCloseIcon={getCopy('btn__cancel')}
      onDismissFocusRef={anchorRef}
      icon={() => <BankIdIcon size={48} colorToken="$text-primary" />}
    >
      <Body size="medium">{getCopy('bankid__screen__select__device')}</Body>
      <ButtonContainer>
        <Button
          size="medium"
          variant="primary"
          fullWidth
          onClick={() => onSelectedDevice('same-device')}
        >
          {getCopy('bankid__button_open')}
        </Button>
        <Button
          size="medium"
          variant="secondary"
          fullWidth
          onClick={() => onSelectedDevice('other-device')}
        >
          {getCopy('bankid__button_open_other_device')}
        </Button>
        <Button onClick={onAbort} size="medium" variant="link">
          {getCopy('btn__cancel')}
        </Button>
      </ButtonContainer>
    </Dialog.Passive>
  );
}

const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '$spacing-32',
  gap: '$spacing-16',
});
