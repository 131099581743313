import { isAuthenticated, setAuthSession } from '@kivra/sdk/identity';
import { getQueryValue } from '@kivra/sdk/common';
import { updateConfig } from '@kivra/sdk/bank-id';
import type { Config, Copy } from '../types';
import { redirectToAuthenticate } from '../utils/redirect-to-authenticate';
import { getCopy } from './copy';
import { initSentry } from './sentry';
import { getConfig } from './config';
import { initFeSdk } from './init-fe-sdk';

export async function bootstrap(
  location: Location,
  cb: (config: Config, copy: Copy) => void
): Promise<void> {
  const config = await getConfig();
  initFeSdk(config);
  initSentry(config);
  updateConfig({
    bankIdPollFrequency: config.bankid_polling_freq,
  });
  const copy = await getCopy();
  if (getTargetUri(location) === 'auth/kivra/return') {
    const accessToken = getQueryValue('access_token');
    if (!accessToken) {
      throw new TypeError(`Missing accessToken in ${window.location.href}`);
    } else {
      await setAuthSession({}, accessToken);
      cb(config, copy);
    }
  } else if (!isAuthenticated()) {
    redirectToAuthenticate(config);
  } else {
    cb(config, copy);
  }
}

export const getTargetUri = ({
  hash,
  pathname,
}: {
  hash: string;
  pathname: string;
}): string | undefined => {
  let uri = '/';
  if (pathname && pathname !== '/') {
    uri = pathname;
  } else {
    uri = hash;
  }
  while (uri.charAt(0) === '#' || uri.charAt(0) === '/') {
    uri = uri.substring(1);
  }
  return uri.split('#')[0];
};
