import {
  Body,
  Button,
  Dialog,
  styled,
  BankIdDialog,
} from '@kivra/react-components';
import {
  AlertFilledIcon,
  DocumentSignIcon,
} from '@kivra/react-components/icons';
import { isDeviceCompatibleWithBankId } from '@kivra/sdk/bank-id';
import { signAgreementWithBankId } from '@sdk/agreement';
import React, { useRef, useState } from 'react';
import { useUserId } from '../../context/global-context';
import { getCopy } from '../../utils/copy';
import type { BankIdSelectDeviceDialogType } from '../bank-id/select-device';
import { BankIdSelectDeviceDialog } from '../bank-id/select-device';

type Props = {
  agreementKey: string;
  onSigned: () => Promise<void>;
};

export const SignButton = ({
  agreementKey,
  onSigned,
}: Props): React.JSX.Element => {
  const [dialog, setDialog] = useState<BankIdSelectDeviceDialogType | 'error'>(
    null
  );
  const buttonRef = useRef<HTMLButtonElement>(null);
  const userId = useUserId();

  const onSignClicked = (): void => {
    if (isDeviceCompatibleWithBankId()) {
      setDialog('select-device');
    } else {
      setDialog('other-device');
    }
  };

  return (
    <>
      {dialog === 'select-device' && (
        <BankIdSelectDeviceDialog
          anchorRef={buttonRef}
          onAbort={() => setDialog(null)}
          onSelectedDevice={dialogType => setDialog(dialogType)}
          open={Boolean(dialog)}
        />
      )}
      {(dialog === 'same-device' || dialog === 'other-device') && (
        <BankIdDialog
          title={getCopy('qrcode__bankid__screen__title')}
          function={signAgreementWithBankId}
          onStart={start => start(userId, agreementKey)}
          onError={_ => {
            setDialog('error');
          }}
          onAbort={() => {
            setDialog(null);
          }}
          onSigned={() => {
            void onSigned();
            setDialog(null);
          }}
          useBankIdOnDevice={dialog === 'same-device'}
          onDismissFocusRef={buttonRef}
        />
      )}
      {dialog === 'error' && (
        <Dialog.Passive
          dataTestId="signing-error-dialog"
          ariaLabelCloseIcon={getCopy('btn__close_dialog')}
          title={getCopy('error_generic__title')}
          open={Boolean(dialog)}
          onClose={() => setDialog(null)}
          onDismissFocusRef={buttonRef}
          largeIcon={
            <AlertFilledIcon size={48} colorToken="$button-dismissive" />
          }
        >
          <Body size="medium" gutterBottom>
            {getCopy('signatures__dialog_signing_bank_id_error_body')}
          </Body>
        </Dialog.Passive>
      )}
      <StyledButton
        ref={buttonRef}
        onClick={onSignClicked}
        size="medium"
        dataTestId="SignButton"
      >
        {getCopy('signatures__sign_button')}
        <DocumentSignIcon size={24} />
      </StyledButton>
    </>
  );
};

const StyledButton = styled(Button)({
  position: 'fixed',
  bottom: '$spacing-48',
  left: '50%',
  transform: 'translateX(-50%)',
});
