import { Body, Flex, css } from '@kivra/react-components';
import { HourglassIcon, ReminderIcon } from '@kivra/react-components/icons';
import type { Agreement } from '@sdk/agreement';
import React from 'react';
import {
  getAgreementListItemSubLabel,
  getStatus,
  showExpirationTimerIcon,
} from '../core';
import { getCopy } from '../utils/copy';

interface Props {
  agreement: Agreement;
}

export const ExpirationDateTexts = ({
  agreement,
}: Props): React.JSX.Element => {
  return (
    <Flex alignItems="center">
      {showExpirationTimerIcon(agreement) && (
        <ReminderIcon
          size={12}
          className={css({ marginRight: '$spacing-2' })}
        />
      )}
      {getStatus(agreement) === 'generating' && (
        <HourglassIcon
          size={12}
          className={css({ marginRight: '$spacing-2' })}
        />
      )}{' '}
      <Body size="small" color="$text-secondary">
        {getAgreementListItemSubLabel(getCopy, agreement)}
      </Body>
    </Flex>
  );
};
