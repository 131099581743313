import {
  styled,
  topbarHeight,
  topbarHeightMedium,
} from '@kivra/react-components';

export const Main = styled.main({
  backgroundColor: '$background-primary',
  display: 'flex',
  flexDirection: 'column',
  minHeight: `calc(100vh - ${topbarHeight}px)`,
  $medium: {
    minHeight: `calc(100vh - ${topbarHeightMedium}px)`,
  },
});
