import React from 'react';
import {
  Body,
  Heading,
  Margin,
  Button,
  Loader,
  css,
} from '@kivra/react-components';
import { useConfig } from '../../context/global-context';
import { Italic } from '../utils/italic';
import { getCopy } from '../../utils/copy';
import {
  MessageCard,
  KivraReadMoreLink,
  SuccessIcon,
  DownloadButton,
} from './message-card';

export const DelegateWaitingForOthers = (): React.JSX.Element => {
  return (
    <MessageCard>
      <SuccessIcon />
      <Heading size="medium" align="center">
        {getCopy('signatures__banner_delegate_waiting_header')}
      </Heading>
      <Body size="medium" align="center" gutterBottom>
        {getCopy('signatures__banner_delegate_waiting_body')}
      </Body>
    </MessageCard>
  );
};

export const DelegateComplete = (): React.JSX.Element => (
  <MessageCard>
    <SuccessIcon />
    <Heading size="medium" align="center">
      {getCopy('signatures__banner_delegate_completed_header')}
    </Heading>
    <Body size="medium" align="center" gutterBottom>
      {getCopy('signatures__banner_delegate_completed_body')}
    </Body>
  </MessageCard>
);

export const KivraUserWaitingForOthers = (): React.JSX.Element => (
  <MessageCard>
    <SuccessIcon />
    <Heading size="medium" align="center">
      {getCopy('signatures__banner_kivra_user_waiting_header')}
    </Heading>
    <Body size="medium" align="center" gutterBottom>
      {getCopy('signatures__banner_kivra_user_waiting_body')}
    </Body>
    <Body size="medium" align="center" gutterBottom>
      <Italic>{getCopy('signatures__banner_kivra_user_thank_you')}</Italic>
    </Body>
  </MessageCard>
);

export const KivraUserComplete = (): React.JSX.Element => {
  const { oauth_endpoint_url } = useConfig();
  return (
    <MessageCard>
      <SuccessIcon />
      <Heading size="medium" align="center">
        {getCopy('signatures__banner_kivra_user_completed_header')}
      </Heading>
      <Body size="medium" align="center" gutterBottom>
        {getCopy('signatures__banner_kivra_user_completed_body')}
      </Body>
      <Body size="medium" align="center" gutterBottom>
        <Italic>{getCopy('signatures__banner_kivra_user_thank_you')}</Italic>
      </Body>
      <Margin bottom={16}>
        <Button
          size="medium"
          onClick={() => window.open(oauth_endpoint_url, '_blank')}
        >
          {getCopy('signatures__banner_kivra_user_view_agreement')}
        </Button>{' '}
      </Margin>
    </MessageCard>
  );
};

export const NonKivraUserWaitingForOthers = ({
  affiliateCode,
}: {
  affiliateCode: string;
}): React.JSX.Element => (
  <MessageCard>
    <SuccessIcon />
    <Heading size="medium" align="center">
      {getCopy('signatures__banner_non_kivra_user_waiting_header')}
    </Heading>
    <Body size="medium" align="center" gutterBottom>
      {getCopy('signatures__banner_non_kivra_user_waiting_body')}
    </Body>
    <Margin top={16} bottom={16}>
      <Button
        size="medium"
        onClick={() => window.open(affiliateCode, '_blank')}
      >
        {getCopy('signatures__banner_non_kivra_user_waiting_button')}
      </Button>
    </Margin>
    <Margin bottom={16}>
      <KivraReadMoreLink />
    </Margin>
  </MessageCard>
);

export const NonKivraUserComplete = ({
  affiliateCode,
}: {
  affiliateCode: string;
}): React.JSX.Element => (
  <MessageCard>
    <SuccessIcon />
    <Heading size="medium" align="center">
      {getCopy('signatures__banner_non_kivra_user_completed_header')}
    </Heading>
    <Body size="medium" align="center" gutterBottom>
      {getCopy('signatures__banner_non_kivra_user_completed_body')}
    </Body>
    <Margin top={16} bottom={16}>
      <Button
        size="medium"
        onClick={() => window.open(affiliateCode, '_blank')}
      >
        {getCopy('signatures__banner_non_kivra_user_completed_button')}
      </Button>
    </Margin>
    <DownloadButton />
  </MessageCard>
);

export const Generating = (): React.JSX.Element => (
  <MessageCard>
    <div
      className={css({ marginBottom: '$spacing-16' })}
      data-test-id="GeneratingLoader"
    >
      <Loader size="large" />
    </div>
    <Heading size="medium" align="center">
      {getCopy('signatures__generating_agreement')}
    </Heading>
    <Body size="medium" align="center" gutterBottom>
      {getCopy('signatures__generating_agreement_body')}
    </Body>
  </MessageCard>
);

export const GeneratingPollAttemptsExceeded = (): React.JSX.Element => (
  <MessageCard>
    <SuccessIcon />
    <Heading size="medium" align="center">
      {getCopy('signatures__generating_agreement')}
    </Heading>
    <Body size="medium" align="center" gutterBottom>
      {getCopy('signatures__max_poll_attempts_exceeded_body')}
    </Body>
  </MessageCard>
);
