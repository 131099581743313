import type { ComponentClass, FunctionComponent } from 'react';
import React from 'react';
import type { RouteChildrenProps, RouteProps } from 'react-router';
import { Route } from 'react-router';
import { isAuthenticated } from '@kivra/sdk/identity';
import { useConfig } from '../context/global-context';
import { redirectToAuthenticate } from '../utils/redirect-to-authenticate';

interface Props extends RouteProps {
  component:
    | FunctionComponent<RouteChildrenProps>
    | ComponentClass<RouteChildrenProps>;
}

export const PrivateRoute = ({
  component: Component,
  ...rest
}: Props): React.JSX.Element | null => {
  const config = useConfig();

  if (!isAuthenticated()) {
    redirectToAuthenticate(config);
    return null;
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
};
