import type { Config } from '../types';

interface ConfigWindow extends Window {
  CONFIG_URL: string;
}

declare const window: ConfigWindow;

function handleResponse(res: Response): Promise<Config> {
  if (res.ok) {
    return res.json() as Promise<Config>;
  }
  throw new Error(res.statusText);
}

async function getDefaultConfig(): Promise<Config> {
  return fetch('/config-default.json').then(handleResponse);
}

async function getRemoteConfig(): Promise<Config> {
  return fetch(window.CONFIG_URL)
    .then(handleResponse)
    .catch(() => ({}) as Config);
}

export async function getConfig(): Promise<Config> {
  const defaultConfig = await getDefaultConfig();
  const remoteConfig = await getRemoteConfig();
  return Object.assign(defaultConfig, remoteConfig);
}
