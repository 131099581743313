import type { Agreement } from '@sdk/agreement';
import React from 'react';
import { getStatus } from '../../core';
import { getCopy } from '../../utils/copy';
import { ListGroup } from './list-group';

interface Props {
  agreements: Agreement[] | [];
}

export const ListGroups = ({ agreements }: Props): React.JSX.Element => {
  return (
    <>
      <ListGroup
        agreements={agreements.filter(a => getStatus(a) === 'shouldSign')}
        title={getCopy('signatures__agreement_list_should_sign_heading')}
      />
      <ListGroup
        agreements={agreements.filter(a => getStatus(a) === 'waitingForOthers')}
        title={getCopy('signatures__agreement_list_waiting_for_others_heading')}
      />
      <ListGroup
        agreements={agreements.filter(
          a => getStatus(a) === 'completed' || getStatus(a) === 'generating'
        )}
        title={getCopy('signatures__agreement_list_completed_heading')}
      />
      <ListGroup
        agreements={agreements.filter(a => getStatus(a) === 'revoked')}
        title={getCopy('signatures__agreement_list_revoked_documents_heading')}
      />
    </>
  );
};
