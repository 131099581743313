import { Button, TopBar } from '@kivra/react-components';
import { ArrowLeftIcon, LogoutIcon } from '@kivra/react-components/icons';
import { revokeToken, userSession } from '@kivra/sdk/identity';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useConfig } from '../context/global-context';
import { getCopy } from '../utils/copy';
import { redirectToAuthenticate } from '../utils/redirect-to-authenticate';

type Props = {
  goBackEnabled?: boolean;
};

export const SignaturesTopBar = ({
  goBackEnabled = false,
}: Props): React.JSX.Element => {
  const history = useHistory();
  const config = useConfig();

  const onLogoutClick = async (): Promise<void> => {
    await revokeToken();
    userSession.deleteSession();
    redirectToAuthenticate(config);
  };

  return (
    <TopBar
      alignLogo="left"
      hideLogo={goBackEnabled}
      logoText={getCopy('signatures__signatures')}
      logoHref=""
      startAdornment={
        goBackEnabled && (
          <Button
            variant="secondary"
            size="small"
            onClick={() => history.push('/')}
          >
            <Button.Icon iconComponent={ArrowLeftIcon} />
            {getCopy('btn__backward')}
          </Button>
        )
      }
      endAdornment={
        <Button variant="link" onClick={onLogoutClick}>
          {getCopy('misc_option__logout')}
          <Button.Icon iconComponent={LogoutIcon} />
        </Button>
      }
    />
  );
};
