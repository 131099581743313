import type { Agreement } from '@sdk/agreement';
import { Caption, Margin, styled } from '@kivra/react-components';
import React from 'react';
import { ListItem } from './list-item';

interface Props {
  agreements: Agreement[];
  title: string;
}
export const ListGroup = ({
  agreements,
  title,
}: Props): React.JSX.Element | null => {
  return agreements.length ? (
    <Margin bottom={32}>
      <Margin all={8}>
        <Caption color="$text-secondary">
          {`${title.toUpperCase()} (${agreements.length})`}
        </Caption>
      </Margin>

      <ListWrapper>
        {agreements.map((agreement: Agreement) => (
          <ListItem agreement={agreement} key={agreement.key} />
        ))}
      </ListWrapper>
    </Margin>
  ) : null;
};

const ListWrapper = styled('div')({
  overflow: 'hidden',
  boxShadow: '$shadow-floating',
  borderRadius: '$radius-medium',
});
