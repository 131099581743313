import { BrowserRouter, Switch } from 'react-router-dom';
import React from 'react';
import { AgreementPageContainer } from '../containers/agreement-page-container';
import { AgreementsPageContainer } from '../containers/agreements-page-container';
import { PrivateRoute } from './private-route';

export const Routes = (): React.JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/" component={AgreementsPageContainer} />
        <PrivateRoute
          exact
          path="/:agreementKey/"
          component={AgreementPageContainer}
        />
      </Switch>
    </BrowserRouter>
  );
};
