import { bankIdProcess } from '@kivra/sdk/bank-id';
import {
  convertPropertyNamesToCamelCase,
  coreRequest,
} from '@kivra/sdk/common';
import { userSession } from '@kivra/sdk/identity';
import type { BankIdObservable, BankIdOrderV2 } from '@kivra/sdk/types/bank-id';
import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import type { Agreement, AgreementDocument } from './types';

/**
 * Fetch the meta data about the agreement, including parties and tenant info, etc. Does not contain the actual pdf
 * document to be signed.
 */
export function getAgreement(
  userKey: string,
  agreementKey: string
): Promise<Agreement> {
  return coreRequest
    .get<Record<string, FixThisAnyLater>>({
      path: `/v1/user/${userKey}/agreement/${agreementKey}`,
      accessToken: userSession.getToken(),
    })
    .then(response => convertPropertyNamesToCamelCase(response) as Agreement);
}

export function getAgreements(userKey: string): Promise<Agreement[]> {
  return coreRequest
    .get<Record<string, FixThisAnyLater>[]>({
      path: `/v1/user/${userKey}/agreement`,
      accessToken: userSession.getToken(),
    })
    .then(response =>
      response.map(
        agreement => convertPropertyNamesToCamelCase(agreement) as Agreement
      )
    );
}

/**
 * Will return a base64 encoded pdf, the original agreement (before it has been signed by all parties)
 */
export function getAgreementOriginal(
  userKey: string,
  agreementKey: string
): Promise<AgreementDocument> {
  return coreRequest.get({
    path: `/v1/user/${userKey}/agreement/${agreementKey}/original`,
    accessToken: userSession.getToken(),
  });
}

/**
 * This will initialize a bankid sign process. After this call, BankIdHandler.checkBankIdProgress should be called
 * to check progress on the signing
 */
export async function initSignAgreement(
  userKey: string,
  agreementKey: string
): Promise<BankIdOrderV2> {
  return coreRequest
    .post<FixThisAnyLater>({
      path: `/v1/user/${userKey}/agreement/${agreementKey}/init_sign`,
      accessToken: userSession.getToken(),
      payload: {},
    })
    .then(response => {
      return {
        autoStartToken: response.auto_start_token,
        bankidOrderKey: response.kivraObjKey,
        nextPollUrl: `/v2/bankid/${response.kivraObjKey}`,
        qrData: response.qr_data,
        qrCode: response.qr_code,
      };
    });
}

/**
 * Will call a function in the api to actually "sign" the agreement, in the Kivra way
 */
export async function signAgreement(
  userKey: string,
  agreementKey: string,
  bankIdOrderKey: string
): Promise<Agreement> {
  return coreRequest.post({
    path: `/v1/user/${userKey}/agreement/${agreementKey}/sign`,
    accessToken: userSession.getToken(),
    payload: { bankid_order_key: bankIdOrderKey },
  });
}

/**
 * This function is meant to be polled until the status says that the completed signed pdf is rendered and then it
 * will be included in the response as the data property.
 * So: the returned object will contain status and data.
 */
export async function getAgreementCovenant(
  userKey: string,
  agreementKey: string
): Promise<AgreementDocument> {
  return coreRequest.get({
    path: `/v1/user/${userKey}/agreement/${agreementKey}/covenant`,
    accessToken: userSession.getToken(),
  });
}

/**
 * Starts sign agreement with BankID.
 * Gets an update for every poll cycle and finally a kivra token.
 * If anything goes wrong, it will throw a bankid error or a
 * general network error.
 */
export function signAgreementWithBankId(
  userKey: string,
  agreementKey: string
): BankIdObservable<Agreement, BankIdOrderV2> {
  const createOrder = (): Promise<BankIdOrderV2> =>
    initSignAgreement(userKey, agreementKey);
  const onSuccess = (bankidOrder: BankIdOrderV2): Promise<Agreement> =>
    signAgreement(userKey, agreementKey, bankidOrder.bankidOrderKey);
  return bankIdProcess<Agreement, BankIdOrderV2>(createOrder, onSuccess);
}
