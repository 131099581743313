import { getCopyString } from '@kivra/sdk/copy-consumer';
import type { Copy, GetCopyArguments } from '../types/copy';
import type { CopyKeysWithoutArgs } from '../types/copy-keys';

let copy: Copy;

export const setCopy = (newCopy: Copy): void => {
  copy = newCopy;
};

export const getCopy = (
  ...args: GetCopyArguments | [CopyKeysWithoutArgs]
): string => {
  return getCopyString<GetCopyArguments>(copy as Copy)(
    ...(args as GetCopyArguments)
  );
};

export type GetCopy = (
  ...args: GetCopyArguments | [CopyKeysWithoutArgs]
) => string;
