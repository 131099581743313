import {
  AnimatedItem,
  Button,
  Flex,
  Margin,
  css,
  styled,
} from '@kivra/react-components';
import {
  AlertFilledIcon,
  CheckmarkCircleIcon,
} from '@kivra/react-components/icons';
import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { useAgreementDocumentState } from '../../context/agreement-context';
import { downloadDocument } from '../../core';
import { getCopy } from '../../utils/copy';
import { KivraBannerDialog } from './kivra-banner-dialog';

export const MessageCard = ({
  children,
}: {
  children: ReactNode;
}): React.JSX.Element => {
  return (
    <AnimatedItem>
      <MessageCardWrapper data-component-type="MessageCard">
        <div className={css({ maxWidth: '25rem', margin: 'auto' })}>
          <Flex alignItems="center" direction="column">
            {children}
          </Flex>
        </div>
      </MessageCardWrapper>
    </AnimatedItem>
  );
};

const MessageCardWrapper = styled.div({
  backgroundColor: '$background-secondary',
  borderRadius: '$radius-medium',
  boxShadow: '$shadow-floating',
  display: 'flex',
  justifyContent: 'center',
  padding: ['$spacing-48', '$spacing-16', '$spacing-64'],
});

export const KivraReadMoreLink = (): React.JSX.Element => {
  const [showDialog, setShowDialog] = useState(false);
  const kivraBannerDialogElement = (
    <KivraBannerDialog showDialog={showDialog} setShowDialog={setShowDialog} />
  );
  return (
    <>
      {kivraBannerDialogElement}
      <Button variant="link" size="medium" onClick={() => setShowDialog(true)}>
        {getCopy('signatures__banner_non_kivra_user_read_about')}
      </Button>
    </>
  );
};

export const DownloadButton = ({
  buttonVariant,
}: {
  buttonVariant?: 'primary';
}): React.JSX.Element | null => {
  const { value: agreementDocument } = useAgreementDocumentState();
  if (!agreementDocument) {
    return null;
  }
  return (
    <AnimatedItem animation="put-down">
      <Margin bottom={16}>
        <Button
          dataTestId="DownloadButton"
          size="medium"
          variant={buttonVariant || 'link'}
          onClick={() => downloadDocument(agreementDocument)}
        >
          {getCopy('btn__content_actions_download')}
        </Button>
      </Margin>
    </AnimatedItem>
  );
};

export const ErrorIcon = (): React.JSX.Element => {
  return (
    <Margin bottom={16}>
      <AlertFilledIcon size={48} colorToken="$button-dismissive" />
    </Margin>
  );
};

export const SuccessIcon = (): React.JSX.Element => {
  return (
    <Margin bottom={16}>
      <CheckmarkCircleIcon size={48} colorToken="$active-highlight" />
    </Margin>
  );
};
