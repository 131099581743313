import { getTargetMarket } from '@kivra/sdk/common';
import { captureException } from '@kivra/sdk/log';
import { ErrorPage } from '@kivra/react-components';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app';
import { bootstrap } from './bootstrap/bootstrap';
import type { Config, Copy } from './types';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

function renderApp(config: Config, copy: Copy): void {
  root.render(<App copy={copy} config={config} />);
}

bootstrap(window.location, renderApp).catch(error => {
  const eventId = captureException(error);
  const market = getTargetMarket();
  root.render(
    <ErrorPage exceptionId={eventId} market={market} useDefaultTexts={true} />
  );
});
