import type { Agreement } from '@sdk/agreement';
import React from 'react';
import {
  Margin,
  Flex,
  css,
  Heading,
  AnimatedItem,
  Button,
} from '@kivra/react-components';
import { DownloadIcon } from '@kivra/react-components/icons';
import type { LegitimateAny } from '@kivra/sdk/types/util/any';
import { useConfig } from '../../context/global-context';
import { ExpirationDateTexts } from '../expiration-date-text';
import { useAgreementDocumentState } from '../../context/agreement-context';
import { getCopy } from '../../utils/copy';

interface Props {
  agreement: Agreement;
}

export const AgreementHeader = ({ agreement }: Props): React.JSX.Element => {
  const { kivra_static_origin } = useConfig();
  const { value: agreementDocument } = useAgreementDocumentState();
  const { subject, tenant } = agreement;
  const downloadDocumentElement = agreementDocument && (
    <AnimatedItem>
      <a
        href={'data:application/pdf;base64,' + agreementDocument.data}
        download={`${agreementDocument.name}`}
      >
        <Button
          aria-label={getCopy('btn__content_actions_download')}
          onClick={undefined as LegitimateAny}
          variant="link"
        >
          <Button.Icon iconComponent={() => <DownloadIcon size={24} />} />
        </Button>
      </a>
    </AnimatedItem>
  );
  return (
    <Margin bottom={8}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <img
            className={css({
              width: '3.75rem',
              height: '3.75rem',
              borderRadius: '$radius-circle',
            })}
            src={`${kivra_static_origin}/img/tenant/${tenant.key}/icon.png`}
            alt=""
          />
          <Margin left={8}>
            <Margin bottom={4}>
              <Heading size="small">{subject}</Heading>
            </Margin>
            <ExpirationDateTexts agreement={agreement} />
          </Margin>
        </Flex>
        {downloadDocumentElement}
      </Flex>
    </Margin>
  );
};
